<i18n locale="th" lang="yaml">
page.title : "ซ่อมหน้างาน : {name}"
</i18n>
<template>
	<div>
		<router-view :service="service"></router-view>
	</div>
</template>

<script>
import axios from 'axios'
import PageMixin from '@mixins/PageMixin.vue'
export default {
	mixins: [PageMixin],
	page() {
		return {
			title: this.$t('page.title', { name: this.service.service_no }),
		}
	},
	data() {
		return {
			service: {
				service_no: '',
			},
		}
	},
	watch: {
		$route(newVal) {
			if (!this.service || newVal.params.id != this.service.id) {
				this.fetchOnfieldData()
			}
		},
	},
	mounted() {
		this.fetchOnfieldData()
	},
	methods: {
		fetchOnfieldData() {
			const onfieldId = this.$route.params.id
			if (!onfieldId) {
				return
			}
			this.showPageLoading(true)
			axios
				.get(`/api/services/${onfieldId}`)
				.then((response) => {
					this.service = response.data.data.service
					this.addBreadcrumbParams({
						serviceNo: this.service.service_no,
					})
				})
				.catch((error) => {
					this.fetchError(error)
				})
				.finally(() => {
					this.hidePageLoading()
				})
		},
	},
}
</script>
